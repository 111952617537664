export default {
  data() {
    return {};
  },
  created() {
    if (this.$route.query.type == 1) {
      // 绑定公众号
    } else {
      // 注册成功
    }
  }
};